<template>
  <div class="newsDetail">
    <!-- PC -->
    <div
      class="
        productTop
        hidden-xs-only
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/swiper/swiperItem1.png" alt="" />
    </div>
    <!-- mobile -->
    <div
      class="
        mobileProductTop
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/mobile/index/index_1.png" alt="" />
    </div>
    <div class="location hidden-xs-only">
        <div class="section wow animate__animated animate__fadeInLeft">
            <div class="tarbar">
                <span>Location：</span>
                <span @click="handleChange(1)">HOME</span>
                <span>></span>
                <span @click="goNews()">InfoCenter</span>
                <span>></span>
                <span class="newsCenter">InfoCenter Detail</span>
            </div>
        </div>
    </div>
    <div class="newsContent">
        <div class="newsContent_top">
            <div class="section">
                <div class="detail">
                    <div class="detailTitle">{{detail.engTitle}}</div>
                    <img v-if="detail.picture" class="detailImg" :src=" url + detail.picture.fileUrl" alt="">
                    <div class="detailContent" v-html="detail.engProfile"></div>
                    <div class="detailContent" v-html="detail.engContent"></div>
                </div>
            </div>
        </div>
        <div class="newsContent_bottom hidden-xs-only">
            <div class="section">
                <div class="other">
                    <div class="up" v-if="prevDetail" @click="goPrevPage(prevDetail.id)">上一篇：{{prevDetail.engTitle}}</div>
                    <div class="down" v-if="nextDetail" @click="goNextPage(nextDetail.id)">下一篇：{{nextDetail.engTitle}}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import { noticePage,noticeDetail } from '../../api/management'
export default {
  data() {
    return {
      url:process.env.VUE_APP_WEB_URL,
      id:this.$route.query.id,
      detail:{
        imgSrc:require('../../assets/images/news/news_1.png'),
        title:'葵花是世界上非常奇异的植物，黄金的花冠',
        content:'美术生是世界上非常奇异的物种，比别人多考两次高考，一次联考，一次校考，一次文化考，三次大考，三个大关，追逐这心中的那个梦想，那个太阳，从未动摇。美术生是世界上非常奇异的物种，比别人多考两次高考，一次联考，一次校考，一次文化考，三次大考，三个大关，追逐这心中的那个梦想，那个太阳，从未动摇。美术生是世界上非常奇异的物种，比别人多考两次高考，一次联考，一次校考，一次文化考，三次大考，三个大关，追逐这心中的那个梦想，那个太阳，从未动摇。',
      },
      Info:{
        pageSize:1000,
        current:1
      },
      list:[],
      index:'',
      prevDetail:{},
      nextDetail:{}
      
    };
  },
  created() {
    this.getAllPage()
    this.getDetail() 
  },
  mounted() {
  },
  methods:{
    // 所有的新闻
    async getAllPage() {
      const res = await noticePage(this.Info) 
      this.list = res.data.records
      let Ids = []
      this.list.forEach(item => {
        Ids.push(item.id)
      });
      for (let i = 0; i < Ids.length; i++) {
        if(Ids[i]==this.id) {
          this.index = i
        }
      }
      this.prevDetail = this.list[this.index-1]
      this.nextDetail = this.list[this.index+1]
    },
    async getDetail() {
      const res = await noticeDetail(this.id)
      // console.log(res);
      this.detail = res.data
    },
    handleChange(num) {
      this.$store.commit("changeNum",num)
      this.$router.push('/indexEn')
    },
    goNews(){
        this.$router.push('/newsEn')
    },
    // 上一篇
    goPrevPage(id) {
      console.log(id);
      // this.getDetail(id)
      this.$router.push({path:'newsDetailEn',query:{id:id}})
    },
    // 下一篇
    goNextPage(id) {
      // this.getDetail(id)
      this.$router.push({path:'newsDetailEn',query:{id:id}})
    }
    
  }
};
</script>
<style lang="scss">
.newsDetail {
  padding-top: 92px;
  .productTop {
    img {
      width: 100%;
      height: 482px;
    }
  }
  .location {
    padding: 50px 0;
    .section {
        font-size: 18px;
        .tarbar {
            cursor: pointer;
            .newsCenter {
                color: #197dbe;
            }
        }
    }
  }
  .newsContent {
    .newsContent_top {
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 50px;
        .section {
            .detail {
                padding: 0 150px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .detailTitle{
                    text-align: center;
                    padding: 60px 0 35px 0;
                    font-size: 30px;
                }
                .detailImg{
                    height: 100%;
                    margin-bottom: 25px;
                }
                .detailContent {
                    text-indent: 2em;
                    font-size: 18px;
                    line-height: 40px;

                }

            }

        }
    }
    .newsContent_bottom{
        .section {
            .other {
                padding: 25px 150px 60px 150px;
                font-size: 24px;
                .up {
                    margin-bottom: 25px;
                    cursor: pointer;
                }
                .up:hover {
                    margin-bottom: 25px;
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    }
                .down{
                    cursor: pointer;
                }
                .down:hover {
                    display: inline-block;
                    background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                }
            }
        }
    }
    
  }
  
}
@media (max-width: 768px) {
  .newsDetail {
    padding-top: 0;
    .mobileProductTop {
      img {
        height: 4rem;
      }
    }
    .newsContent {
        .newsContent_top {
            border: none;
            padding-bottom: .5rem;
            .section {
                .detail {
                    padding: 0;
                    .detailTitle {
                        font-weight: bold;
                        font-size: .35rem;
                        padding: .4rem 0;
                    }
                    .detailContent {
                        font-size: .3rem;
                        line-height: .6rem;
                    }
                }
            }
        }
    }
    
  }
}
</style>